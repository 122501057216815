import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';
import quasarUserOptions from './quasar-user-options'
// import VueQrcodeReader from "vue-qrcode-reader";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'qrcode-reader-vue3'
import { ModelListSelect, MultiSelect } from "vue-search-select" 
import VueQRCodeComponent from 'vue-qrcode-component'
// Vue.component('qr-code', VueQRCodeComponent)


import "vue-search-select/dist/VueSearchSelect.css"

import MODAL_ADD from './components/modal_add.vue'
import MODAL_EDIT from './components/modal_edit.vue'
import MODAL_DELETE from './components/modal_delete.vue'
import MODAL_ALERT from './components/modal_alert.vue'
import test_componen from './components/test_componen.vue'
// import cetak from './components/cetak.vue'


createApp(App)
.use(Quasar, 
    {
        plugins: {
          Notify, Loading
        },
        config: {
          notify: { /* look at QuasarConfOptions from the API card */ },
          loading:{},
        }
    },    
    quasarUserOptions) 
.use(store)
.component('QrcodeStream', QrcodeStream)
.component('ModelListSelect', ModelListSelect)
.component('MultiSelect', MultiSelect)
.component('MODAL_ADD', MODAL_ADD)
.component('MODAL_EDIT', MODAL_EDIT)
.component('MODAL_DELETE', MODAL_DELETE)
.component('test_componen', test_componen)
.component('MODAL_ALERT', MODAL_ALERT)
.component('qr-code', VueQRCodeComponent)
// .component('cetak', cetak)
// .use(VueQrcodeReader)
.use(router).mount('#app')
