<template>
    <q-dialog persistent>
        <q-card style="width: 80vw">
            <q-card-section class="bg-amber">
                <div class="text-overline">EDIT</div>
            </q-card-section>

            <q-separator />
            <form @submit.prevent="metode">
                <q-card-section style="max-height: 50vh;" class="scroll">
                    <slot></slot>                       



                </q-card-section>
                <q-separator />
                <q-card-actions align="right">
                    <q-btn-group push>
                        <q-btn push type="submit" class="text-black" color="amber" label="Edit" icon="edit" size="sm" />
                        <q-btn push color="negative"  label="Batal/Keluar" icon="close" size="sm" v-close-popup />
                    </q-btn-group>
                </q-card-actions>
            </form>

        </q-card>
    </q-dialog>
</template>

<script>
export default { 
props:['metode', 'modelx'],
//   props:{
//     modelx:Boolean,
//     metode:Object


//   },

}
</script>