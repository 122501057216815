<template>
  <!-- <q-page class="flex flex-center">
    <img alt="Quasar logo" src="../assets/logo.svg" style="width: 200px; height: 200px">
  </q-page> -->
  <q-page class="flex">
    <img alt="Quasar logo" src="../assets/logo.svg" style="width: 200px; height: 200px">
  </q-page>
  <!-- <div>
    <p>Hai</p>
  </div> -->
</template>

<style>
</style>

<script>
export default {
  name: 'HelloWorld'
}
</script>
