<template>
  <q-layout view="lHh Lpr lFf">
    <q-header v-show="$store.state.showHide" elevated class="glossy bg-dark">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="$store.state.leftDrawerOpen = !$store.state.leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title style="font-size: 11pt;">
          e-Retribusi Pasar Konsel (V 0.1.3)
        </q-toolbar-title>

        <!-- <div>Quasar v{{ $q.version }}</div> -->
      </q-toolbar>
    </q-header>

    <Sidebars v-show="$store.state.showHide" />


    <q-page-container>
      <router-view/>
      <!-- <HelloWorld /> -->
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
// import HelloWorld from './components/HelloWorld.vue'
import Sidebars from './components/sidebar.vue'

export default {
  name: 'LayoutDefault',

  components: {
    // HelloWorld
    Sidebars
  },

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  }
}
</script>
