var DataStore = require('../store');
var store = DataStore.default

const matauang = (a) => {    
    if (a==null || a==undefined) {
        return '-'
        
    } else{
        return 'Rp. '+ a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}


const HARI_INI = (a) => { 
        // var date = new Date(a);
        var date = a.getUTCFullYear() + '-' +
        ('00' + (a.getUTCMonth()+1)).slice(-2) + '-' +
        ('00' + a.getUTCDate()).slice(-2);
        return date 
}

const timez = (date, tzString) =>{
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}
const tanggal_inputsql = () =>{
    var date = new Date();
    date.toLocaleDateString("en-US", {timeZone: 'Asia/Ujung_Pandang'})

    date = date.getUTCFullYear() + '-' +
    ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
    // date.getUTCDate()
    // ('0' + date.getUTCDate()).slice(-2)
    ('00' + date.getUTCDate()).slice(-2);

return date

}

const tgl_now = () =>{
    const d = new Date();
    let year    = d.getUTCFullYear();
    let month   = ('00'+ (d.getUTCMonth()+1)).slice(-2);
    let day     = ('00'+ d.getUTCDay()).slice(-2);
    return year+'-'+month+'-'+day
}

const tglConvert = (tgl) => {
    var date = new Date(tgl);
    // console.log(date)
    var getBulan = date.getMonth() + 1; var bulan = '';
    if (getBulan == '1') {bulan = 'Jan'} 
    else if(getBulan == '2') {bulan = 'Feb'}
    else if(getBulan == '3') {bulan = 'Mar'}
    else if(getBulan == '4') {bulan = 'Apr'}
    else if(getBulan == '5') {bulan = 'Mei'}
    else if(getBulan == '6') {bulan = 'Jun'}
    else if(getBulan == '7') {bulan = 'Jul'}
    else if(getBulan == '8') {bulan = 'Agt'}
    else if(getBulan == '9') {bulan = 'Sep'}
    else if(getBulan == '10') {bulan = 'Okt'}
    else if(getBulan == '11') {bulan = 'Nov'}
    else if(getBulan == '12') {bulan = 'Des'}

    if (tgl == undefined || tgl == null || tgl == '') {
        var tglku = "-";
        var time = "-"
    } else {
        var tglku = date.getDate() + " " + bulan + " " + date.getFullYear();
        var time = date.getHours() +':'+date.getMinutes()+':'+date.getSeconds()
    }


    return {
        tgl : tglku,
        time : time
    };
}

const timec = () => {
    var isoDateString = new Date(Date.now()).toISOString()
    // new Date().toLocaleString("en-US", {timeZone: "Asia/Makassar"})

    var isoDateString = new Date().toLocaleString()
    // let year    = isoDateString.mo
    // let month   = ('00'+ (isoDateString.getMonth()+1)).slice(-2);
    // let day     = ('00'+ isoDateString.getDate()).slice(-2);

    var hasil = isoDateString 
    console.log(isoDateString);

    // =========================================================================
    return hasil

    
}

const formatDatesx = (date) => {
// Mendapatkan tahun, bulan, dan tanggal dari objek Date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Menggabungkan tahun, bulan, dan tanggal dalam format yyyy-mm-dd
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

const indexing = (hal, limit, index) =>{
    var idx = ((hal-1)*limit)+index
    return idx
}



module.exports = {
    matauang            : matauang, 
    tanggal_inputsql    : tanggal_inputsql, 
    tglConvert          : tglConvert, 
    HARI_INI            : HARI_INI, 
    timez               : timez,
    tgl_now             : tgl_now,
    timec               : timec,

    formatDatesx        : formatDatesx,
    indexing            : indexing,
}