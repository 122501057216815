<template>
    <q-drawer v-model="$store.state.leftDrawerOpen" show-if-above bordered class="bg-grey-3" >
      <q-img class="absolute-top" src="img/istockphoto.png" style="height: 150px">
          <div class="absolute-bottom bg-transparent">
            <q-avatar size="56px" class="q-mb-sm">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png">
            </q-avatar>
            <div class="text-weight-bold">{{$store.state.profile.nama}}</div>
            <div>{{$store.state.profile.email}}</div>
          </div>
        </q-img>

        <q-scroll-area style="height: calc(100% - 150px); margin-top: 150px; border-right: 1px solid #ddd">
          <q-list >
                <!-- <q-img src="https://cdn.quasar.dev/img/parallax2.jpg" style="height: 185px;">
                  <div class="absolute-top text-left">
                
                    <q-avatar rounded size="85px">
                      <img  src="../../public/img/users.png"  /> 
                    </q-avatar>
                    <div class="text-subtitle4">{{$store.state.profile.nama}}</div>
                    <div class="text-subtitle2">{{$store.state.profile.email}}</div>
                    
                  </div>
                </q-img> -->
                
    
            <q-item-label header>Sidebar Menu</q-item-label>
    
            <!-- ====================================== JIKA SINGLE ====================================== -->
            <template v-for="(data, i) in $store.state.list_menu" >
              <q-item clickable :to="data.route" v-if="data.type == 0" :key="i">
                  <q-item-section avatar> <q-avatar :icon="data.icon" :color="data.color" text-color="white" /> </q-item-section>
                  <q-item-section> <q-item-label style="font-size: 12px; color:#303030; font-weight: 600;font-family: 'Poppins', sans-serif;">{{data.title}}</q-item-label> </q-item-section> 
              </q-item>
            <!-- ====================================== JIKA DOUBLE ====================================== -->
    
              <q-expansion-item :header-inset-level="0" v-if="data.type == 1" :key="i" expand-separator  >
                <template v-slot:header>
                  <q-item-section avatar> <q-avatar :icon="data.icon" :color="data.color" text-color="white" /> </q-item-section>
                  <q-item-section> <q-item-label style="font-size: 12px; color:#303030; font-weight: 600;font-family: 'Poppins', sans-serif;">{{data.title}}</q-item-label> </q-item-section>
                </template>
    
                <q-item v-for="(data2, index) in data.subItem" :key="index" clickable :inset-level="0.2" :to="data2.route"> 
                    <q-item-section avatar> <q-avatar :icon="data2.icon" :color="data2.color" text-color="white" size="35px" /> </q-item-section>
                    <q-item-section> <q-item-label style="font-size: 12px;font-weight: 500;font-family: 'Poppins', sans-serif;">{{data2.title}}</q-item-label> </q-item-section> 
                </q-item>          
              </q-expansion-item> 
            </template>
    
    
            <!-- <q-item-label header>Masyarakat</q-item-label> -->
            <!-- <q-item-label header>Admin</q-item-label> -->
    
            <!-- ====================================== JIKA SINGLE ====================================== -->
            <!-- $store.state.list_menu -->
            
              
              
              
     
              
              
              
              
              
            <!-- <q-item clickable to="/retribusi">
              <q-item-section avatar>
                <q-icon name="school" />
              </q-item-section>
              <q-item-section>
                <q-item-label>retribusi</q-item-label>
                <q-item-label caption>retribusi</q-item-label>
              </q-item-section>
            </q-item> 
            <q-item clickable to="/topup">
              <q-item-section avatar>
                <q-icon name="school" />
              </q-item-section>
              <q-item-section>
                <q-item-label>topup</q-item-label>
                <q-item-label caption>topup</q-item-label>
              </q-item-section>
            </q-item> 
            <q-item clickable to="/reg_toko">
              <q-item-section avatar>
                <q-icon name="school" />
              </q-item-section>
              <q-item-section>
                <q-item-label>reg_toko</q-item-label>
                <q-item-label caption>reg_toko</q-item-label>
              </q-item-section>
            </q-item> 
            <q-item clickable to="/bayar">
              <q-item-section avatar>
                <q-icon name="school" />
              </q-item-section>
              <q-item-section>
                <q-item-label>bayar</q-item-label>
                <q-item-label caption>bayar</q-item-label>
              </q-item-section>
            </q-item>  -->
    
    
            <!-- <q-expansion-item :header-inset-level="0" expand-separator icon="perm_identity" label="Master" caption="Data Master/Setting">
              
              <q-item clickable :inset-level="0.2" to="/menu_klp">
                <q-item-section avatar>
                  <q-icon name="school" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Menu Kelompok</q-item-label>
                  <q-item-label caption>Menu Kelompok</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable :inset-level="0.2" to="/menu">
                <q-item-section avatar>
                  <q-icon name="school" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Menu</q-item-label>
                  <q-item-label caption>Menu</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable :inset-level="0.2" to="/unit_retribusi">
                <q-item-section avatar>
                  <q-icon name="school" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Unit Retribusi</q-item-label>
                  <q-item-label caption>Unit Retribusi</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable :inset-level="0.2" to="/unit_retribusi_sub">
                <q-item-section avatar>
                  <q-icon name="school" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Sub Unit Retribusi</q-item-label>
                  <q-item-label caption>Sub Unit Retribusi</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable :inset-level="0.2" to="/register">
                <q-item-section avatar>
                  <q-icon name="school" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Register</q-item-label>
                  <q-item-label caption>Register</q-item-label>
                </q-item-section>
              </q-item>          
            </q-expansion-item> -->
    
            
            <q-separator/>
            <q-item clickable @click="logout()">
              <q-item-section avatar> <q-avatar icon="power_settings_new" color="black" text-color="white" /> </q-item-section>
              <q-item-section> 
                <q-item-label style="font-size: 12px;font-weight: 600; color:#303030;" >Logout</q-item-label> 
                <q-item-label style="font-size: 12px;font-weight: 400; color:#303030;"  caption>Keluar</q-item-label>
              </q-item-section>           
            </q-item>
            <q-separator/>
     
          </q-list>
        </q-scroll-area>

    </q-drawer>
</template>

<script>

import FETCHING from "../library/fetching";
export default {
  data() {
    return {
      list_menu : [],
      FETCHING  : FETCHING,

      profile:{
        nama    : '',
        email   : '',
      }
    }
  },
  methods: {
    logout: function() {
      localStorage.removeItem("token");
      localStorage.removeItem("profile");
      localStorage.removeItem("menu");
      this.$router.push("/login");
    },

    async loadMenu(){
      // console.log('aaaa');

      var get_profile = JSON.parse(localStorage.profile);
      var profileku = get_profile.profile;

      var dataMenu = await this.FETCHING.postMasterMenuGetSideBar(profileku.menu_klp)
      
      this.$store.commit('ubahState', { name : 'list_menu',  list : dataMenu});
      
      
      // this.profile.nama = profileku.nama
      // this.profile.email = profileku.email
      this.loadUser()
      
    },  

    loadUser(){
        var profile = JSON.parse(localStorage.profile) 
        // this.filter.id_unit = profile.profile.id_unit
        this.$store.state.profile.nama = profile.profile.nama
        this.$store.state.profile.email = profile.profile.email
        // console.log(profile.profile)
    },

  },
  mounted() {

    this.loadMenu()
    
    
    
  },
    
}
</script>