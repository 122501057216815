const randColor = () =>{
    var x=Math.round(0xffffff * Math.random()).toString(16);
    var y=(6-x.length);
    var z= "000000";
    var z1 = z.substring(0,y);
    var color= "#" + z1 + x;

    return color
}

const lineChart = (id_chart, judul, datas) => {

    
    // var a = datas
    // console.log("Linechartzzz");
    

    var list_data = datas
    // console.log(list_data);

    // var list_data = [
    //     {
    //         id:1,
    //         name: 'Pasar Tinanggera',
    //         total: 25000,
    //         jan:21,
    //         feb:22,
    //         mar:23,
    //         apr:20,
    //         mei:24,
    //         jun:25,
    //         jul:20,
    //         agu:26,
    //         sep:20,
    //         okt:27,
    //         nov:20,
    //         des:28 
    //     },
    //     {
    //         id:2,
    //         name: 'Pasar Mamasa',
    //         total: 25000,
    //         jan:23,
    //         feb:24,
    //         mar:25,
    //         apr:26,
    //         mei:27,
    //         jun:28,
    //         jul:29,
    //         agu:28,
    //         sep:27,
    //         okt:28,
    //         nov:27,
    //         des:10 
    //     },
    // ]

    var isi = []
    
    list_data.forEach(element => {
        isi.push(
            {"name":element.name, data:[element.jan, element.feb, element.mar, element.apr, element.mei, element.jun, element.jul, element.agu, element.sep, element.okt, element.nov, element.des], color: randColor(), "tahun":element.tahun }
            )
    })
    // console.log("isi");
    // console.log(isi);


    const chart = Highcharts.chart(id_chart , {
        title: {
            text: judul,
            align: 'center',
            margin: 0
        },
        
        xAxis: {
            crosshair: true,
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        },
    
        legend: {
            enabled: true
        },
    
        tooltip: {
            pointFormat: '{series.name} Memiliki <b>Rp. {point.y:,.0f}  </b>'
        },
    
        series: isi,
        
        plotOptions: {
            area: {
                pointStart: 1940,
                borderWidth: 1,
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 1,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },



        
    }); 
    
}

const chart1 = (idhtml, judul) => {
    Highcharts.chart(idhtml, {

        title: {
            text: judul,
            align: 'left'
        },
    
        subtitle: {
            text: 'By Job Category. Source: <a href="https://irecusa.org/programs/solar-jobs-census/" target="_blank">IREC</a>.',
            align: 'left'
        },
    
        yAxis: {
            title: {
                text: 'Number of Employees'
            }
        },
    
        xAxis: {
            accessibility: {
                rangeDescription: 'Range: 2010 to 2020'
            }
        },
    
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        
    
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                pointStart: new Date(Date.now())
            }
        },
    
        series: [
            {
                name: 'Installation & Developers',
                data: [43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174, 155157, 161454, 154610]
            },
        
    ],
    
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    
    });
    
}

const pieChart = (idhtml, judul, datax) => {
    Highcharts.chart(idhtml, {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: judul,
            align: 'left'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [
            {
            name: 'Brands',
            colorByPoint: true,
            data: datax
            // [
            //     {
            //         name: 'Chrome',
            //         y: 70.67,
            //         sliced: true,
            //         selected: true
            //     }, 
            //     {
            //         name: 'Pasar 1',
            //         y: 14.77
            //     },
            //     {
            //         name: 'Pasar 2',
            //         y: 14.77
            //     }
            // ]
        }]
    });
}

const barChart = (idhtml, judul, datax) => {
// Data retrieved from https://gs.statcounter.com/browser-market-share#monthly-202201-202201-bar

// Create the chart
Highcharts.chart(idhtml, {
    chart: {
        type: 'column'
    },
    title: {
        align: 'center',
        text: judul
    },
    subtitle: {
        align: 'left',
        text: '-'
    },
    accessibility: {
        // announceNewData: {
        //     enabled: true
        // }
    },
    xAxis: {
        type: 'category'
    },
    yAxis: {
        title: {
            text: 'Total'
        }

    },
    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                // enabled: true,
                format: 'Rp. {point.y}'
            }
        }
    },

    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>Rp. {point.y}</b><br/>'
    },

    series: [
        {
            // name: 'Browsers',
            colorByPoint: true,
            data: datax, 
        }
    ],
    drilldown: {
        breadcrumbs: {
            position: {
                align: 'right'
            }
        },
        series:datax
        
    }
});

}

const barchartDrill = (idhtml, judul, datax) =>{
    Highcharts.chart(idhtml, {
        chart: {
            type: 'column'
        },
        title: {
            align: 'left',
            text: judul
        },
        subtitle: {
            align: 'left',
            text: 'Data Presentase usaha yang sudah memiliki izin usaha di tiap unit / pasar'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Total presentase kepemilikan ijin usaha'
            }
    
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 1,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.1f}%'
                }
            }
        },
    
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> dari {point.total}  total usaha<br/>'
        },
    
        series: [
            {
                name: 'Pasar',
                colorByPoint: true,
                data: datax
            }
        ],
 
    });    
}
const barchartDrill2 = (idhtml, judul, datax) =>{
    Highcharts.chart(idhtml, {
        chart: {
            type: 'column'
        },
        title: {
            align: 'left',
            text: judul
        },
        subtitle: {
            align: 'left',
            text: 'Data jumlah usaha di tiap unit / pasar'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Total jumlah usaha'
            }
    
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 1,
                dataLabels: {
                    enabled: true,
                    format: 'Total Usaha {point.y}'
                }
            }
        },
    
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} </b> '
        },
    
        series: [
            {
                name: 'Pasar',
                colorByPoint: true,
                data: datax
            }
        ],
 
    });    
}


const barchartNew = (container) =>{

var list_data = [
        {
            id:1,
            name: 'Pasar Tinanggera',
            y: 25000, 
        },
        {
            id:2,
            name: 'Pasar Mamasa',
            y: 25000, 
        },
    ]    
    
    Highcharts.chart(container, {
        chart: {
            type: 'column'
        },
        title: {
            align: 'left',
            text: 'Browser market shares. January, 2022'
        },
        subtitle: {
            align: 'left',
            text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Total percent market share'
            }
    
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.1f}%'
                }
            }
        },
    
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
    
        series: [
            {
                name: 'Browsers',
                colorByPoint: true,
                data: [
                    {
                        name: 'Chrome',
                        y: 63.06,
                        drilldown: 'Chrome'
                    },
                    {
                        name: 'Safari',
                        y: 19.84,
                        drilldown: 'Safari'
                    },
                    {
                        name: 'Firefox',
                        y: 4.18,
                        drilldown: 'Firefox'
                    },
                    {
                        name: 'Edge',
                        y: 4.12,
                        drilldown: 'Edge'
                    },
                    {
                        name: 'Opera',
                        y: 2.33,
                        drilldown: 'Opera'
                    },
                    {
                        name: 'Internet Explorer',
                        y: 0.45,
                        drilldown: 'Internet Explorer'
                    },
                    {
                        name: 'Other',
                        y: 1.582,
                        drilldown: null
                    }
                ]
            }
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: 'right'
                }
            },
            series: [
                {
                    name: 'Chrome',
                    id: 'Chrome',
                    data: [
                        [
                            'v65.0',
                            0.1
                        ],
                        [
                            'v64.0',
                            1.3
                        ],
                        [
                            'v63.0',
                            53.02
                        ],
                        [
                            'v62.0',
                            1.4
                        ],
                        [
                            'v61.0',
                            0.88
                        ],
                        [
                            'v60.0',
                            0.56
                        ],
                        [
                            'v59.0',
                            0.45
                        ],
                        [
                            'v58.0',
                            0.49
                        ],
                        [
                            'v57.0',
                            0.32
                        ],
                        [
                            'v56.0',
                            0.29
                        ],
                        [
                            'v55.0',
                            0.79
                        ],
                        [
                            'v54.0',
                            0.18
                        ],
                        [
                            'v51.0',
                            0.13
                        ],
                        [
                            'v49.0',
                            2.16
                        ],
                        [
                            'v48.0',
                            0.13
                        ],
                        [
                            'v47.0',
                            0.11
                        ],
                        [
                            'v43.0',
                            0.17
                        ],
                        [
                            'v29.0',
                            0.26
                        ]
                    ]
                },
                {
                    name: 'Firefox',
                    id: 'Firefox',
                    data: [
                        [
                            'v58.0',
                            1.02
                        ],
                        [
                            'v57.0',
                            7.36
                        ],
                        [
                            'v56.0',
                            0.35
                        ],
                        [
                            'v55.0',
                            0.11
                        ],
                        [
                            'v54.0',
                            0.1
                        ],
                        [
                            'v52.0',
                            0.95
                        ],
                        [
                            'v51.0',
                            0.15
                        ],
                        [
                            'v50.0',
                            0.1
                        ],
                        [
                            'v48.0',
                            0.31
                        ],
                        [
                            'v47.0',
                            0.12
                        ]
                    ]
                },
                {
                    name: 'Internet Explorer',
                    id: 'Internet Explorer',
                    data: [
                        [
                            'v11.0',
                            6.2
                        ],
                        [
                            'v10.0',
                            0.29
                        ],
                        [
                            'v9.0',
                            0.27
                        ],
                        [
                            'v8.0',
                            0.47
                        ]
                    ]
                },
                {
                    name: 'Safari',
                    id: 'Safari',
                    data: [
                        [
                            'v11.0',
                            3.39
                        ],
                        [
                            'v10.1',
                            0.96
                        ],
                        [
                            'v10.0',
                            0.36
                        ],
                        [
                            'v9.1',
                            0.54
                        ],
                        [
                            'v9.0',
                            0.13
                        ],
                        [
                            'v5.1',
                            0.2
                        ]
                    ]
                },
                {
                    name: 'Edge',
                    id: 'Edge',
                    data: [
                        [
                            'v16',
                            2.6
                        ],
                        [
                            'v15',
                            0.92
                        ],
                        [
                            'v14',
                            0.4
                        ],
                        [
                            'v13',
                            0.1
                        ]
                    ]
                },
                {
                    name: 'Opera',
                    id: 'Opera',
                    data: [
                        [
                            'v50.0',
                            0.96
                        ],
                        [
                            'v49.0',
                            0.82
                        ],
                        [
                            'v12.1',
                            0.14
                        ]
                    ]
                }
            ]
        }
    });
    
}

module.exports = {
    chart1: chart1,
    lineChart: lineChart,
    pieChart: pieChart,
    barChart: barChart,
    barchartDrill: barchartDrill,
    barchartDrill2: barchartDrill2,
    barchartNew: barchartNew,

}