import { createStore } from 'vuex' 

import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js'; ;
// var URL = 'http://192.168.1.7:5045/';
// var URL = 'http://localhost:5045/';
// var URL = 'http://192.168.137.172:5045/';
// var URL = 'http://10.91.178.2:5045/';
var URL = 'https://server-e-retribusi.konaweselatankab.go.id/';

export default createStore({
  state: {
    leftDrawerOpen  :false,
    coba        :'zz ini test',
    showHide    : true,

    url:{
      URL_APP                  : URL,
      URL_DASHBOARD            : URL + 'api/v1/dashboard/',
      URL_WILAYAH              : URL + 'api/v1/m_wilayah/',
      M_UNIT_RETRIBUSI         : URL + 'api/v1/m_unit_retribusi/',
      M_HARGA_RETRIBUSI        : URL + 'api/v1/m_harga_retribusi/',
      M_LIST_UNIT_RETRIBUSI    : URL + 'api/v1/m_list_unit_retribusi/',
      M_MENU                   : URL + 'api/v1/menuList/',
      URL_DM_KLP_USERS         : URL + 'api/v1/kelompokUsers/',
      URL_REGISTER             : URL + 'api/v1/registrasi/',
      URL_DOMPET               : URL + 'api/v1/dompet/',
      LOGIN_URL                : URL + 'auth/',
      
      MASTER_UNIT              : URL + 'api/v1/master_unit/',
      MASTER_LAPAK             : URL + 'api/v1/master_lapak/',
      MASTER_JENIS_USAHA       : URL + 'api/v1/master_jenis_usaha/',
      MASTER_JENIS_USAHA_LIST  : URL + 'api/v1/master_jenis_usaha_list/',
      MASTER_USAHA             : URL + 'api/v1/master_usaha/',
      MASTER_PENGUMUMAN        : URL + 'api/v1/pengumuman/',

      RETRIBUSI                : URL + 'api/v1/retribusi/',
      RETRIBUSI_ALL            : URL + 'api/v1/retribusi_all/',
      LIST_FILTER              : URL + 'api/v1/list_filter/',

      LAPOR_SETORAN            : URL + 'api/v1/lapor_setoran/',
      LAPORAN                  : URL + 'laporan/',
      
    },

    L_PRO:[],
    L_KAB:[],
    L_KEC:[],
    L_DES:[],


    L_RETRIBUSI:[],

    profile:{
      nama          :'',
      email         :'',
      foto_profile  :'',
    },



    type : [
      {id : 0, uraian : 'Single Menu'},
      {id : 1, uraian : 'Multy Menu'}
    ],

    list_menu : null,

    mdl_add     : false,
    mdl_edit    : false,
    mdl_remove  : false, 
  },
  
  getters: {
  },
  mutations: {
    

    ubahState(state, { name, list }) {
      state[name] = list
    },
    ubahBtnState(state, { name, list }) {
      state.btn[name] = list
    },

    test(state) {
      console.log(state.coba);
    },

    notif_berhasil(){
      Notify.create({
        message: 'BERHASIL',
        // caption: '5 minutes ago',
        icon: 'check',
        color: 'secondary',
        position: 'center',
        timeout: 1000
    })
    },
    notif_saldo_kurang(){
      Notify.create({
        message: 'Saldo anda kurang. silahkan isi saldo',
        // caption: '5 minutes ago',
        icon: 'error',
        color: 'orange',
        position: 'center',
        timeout: 1000
    })
    },

    startLoad(){
      Loading.show() 
    },
    stopLoad(){
      Loading.hide()
    },

  },
  actions: {
  },
  modules: {
  }
})
