import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    console.log('Saya masuk')
    next();
  } else {
    next('/login');
  }
}


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/about',
    name: 'about', 
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/register',
    name: 'register', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/master/register.vue')
  },
  {
    path: '/unit_retribusi',
    name: 'unit_retribusi', 
    beforeEnter: isLoggedIn,
    component: () => import('../views/page/master/unit_retribusi.vue')
  },
  {
    path: '/unit_retribusi_sub',
    name: 'unit_retribusi_sub', 
    beforeEnter: isLoggedIn,
    component: () => import('../views/page/master/unit_retribusi_sub.vue')
  },
  {
    path: '/login',
    name: 'login', 
    beforeEnter: loggedInRedirectDashboard,
    component: () => import('../views/page/auth/login.vue')
  },
  {
    path: '/menu',
    name: 'menu', 
    beforeEnter: isLoggedIn,
    component: () => import('../views/page/master/menu.vue')
  },
  {
    path: '/menu_klp',
    name: 'menu_klp', 
    beforeEnter: isLoggedIn,
    component: () => import('../views/page/master/menu_klp.vue')
  },
  {
    path: '/reg_toko',
    name: 'reg_toko', 
    beforeEnter: isLoggedIn,
    component: () => import('../views/page/master/toko.vue')
  },
  {
    path: '/harga_retribusi',
    name: 'harga_retribusi', 
    beforeEnter: isLoggedIn,
    component: () => import('../views/page/master/harga_retribusi.vue')
  },

  {
    path: '/register_user_ret',
    name: 'register_user_ret', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/admin_retribusi/register.vue')
  },

  {
    path: '/retribusi',
    name: 'retribusi', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/admin_retribusi/retribusi.vue')
  },
  {
    path: '/topup',
    name: 'topup', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/admin_retribusi/topup.vue')
  },
  {
    path: '/bayar',
    name: 'bayar', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/admin_retribusi/bayar.vue')
  },
  {
    path: '/cetak_qr',
    name: 'cetak_qr', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/admin_retribusi/cetak_qr.vue')
  },
  {
    path: '/laporan_setor_pasar',
    name: 'laporan_setor_pasar', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/admin_retribusi/laporan_setor_pasar.vue')
  },
  {
    path: '/laporan_setor_pasar_verifikasi',
    name: 'laporan_setor_pasar_verifikasi', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/admin_retribusi/laporan_setor_pasar_verifikasi.vue')
  },
  {
    path: '/cetak',
    name: 'cetak', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../components/cetak.vue')
  },


  // ================================================================================================================
  {
    path: '/master_unit',
    name: 'master_unit', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/master/master_unit.vue')
  },
  {
    path: '/master_lapak',
    name: 'master_lapak', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/master/master_lapak.vue')
  },
  {
    path: '/master_jenis_usaha',
    name: 'master_jenis_usaha', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/master/master_jenis_usaha.vue')
  },
  {
    path: '/master_usaha',
    name: 'master_usaha', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/master/master_usaha.vue')
  },
  {
    path: '/master_regis_masyarakat',
    name: 'master_regis_masyarakat', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/master/master_regis_masyarakat.vue')
  },


  {
    path: '/pengumuman',
    name: 'pengumuman', 
    beforeEnter: isLoggedIn,
    component: () => import(/* webpackChunkName: "about" */ '../views/page/Pengumuman.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
