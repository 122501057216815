<template>
    <div>
        <div>
            <h1>HEAD</h1>
        </div>
        <div>
            <slot></slot>
        </div>
        <div>
            <h1>FOOTER</h1>
        </div> 
        
    </div>
</template>

<script>
export default {
    
}
</script>