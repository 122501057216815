<template>
    <q-dialog persistent>
        <q-card>
                <q-card-section class="row items-center text-center">
                    <q-avatar icon="delete" color="negative" text-color="white" />
                    <span class="q-ml-sm">Anda yakin ingin menghapus data ini?</span>
                </q-card-section>

                <form @submit.prevent="metode">
                    <q-card-actions align="center">
                        <q-btn label="Hapus" type="submit" color="primary" />
                        <q-btn label="Cancel" color="negative" v-close-popup />
                    </q-card-actions>
                </form>

            </q-card>
    </q-dialog>
</template>

<script>
export default { 
props:['metode', 'modelx'],
//   props:{
//     modelx:Boolean,
//     metode:Object


//   },

}
</script>