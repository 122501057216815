<template>
  
  <div style="background: #6c6c6c ">
    
    <div class="row q-pt-md q-pb-md items-center" style="background: radial-gradient(circle, #434343 0%, #222222 100%)"> 
      <div class="col col-xs-12 col-sm-12 col-md-4 q-px-md self-center items-center ">
        <div desktop class="q-py-sm h_filterBar">
          <span class="text-popins text-white ">Dari Tanggal </span>
        </div>
        <div class="row q-my-xs">
          <div class="col-10" style="height: 26px !important;">
            <q-input bg-color="white" v-model="filter.tgl_dari" outlined type="date" placeholder="hhhhhhhhhh" :dense="true"></q-input>
          </div>
          <div class="col-2">
            <q-btn @click="filter.tgl_dari = '', getView()" color="black" style="font-size:10px; height:36px;width: 60px;"
              icon="autorenew" text-color="orange-5"/>
          </div>
        </div>
      </div>
      <div class="col col-xs-12 col-sm-12 col-md-4 q-px-md self-center items-center">
        <div desktop class="q-py-sm h_filterBar">
          <span class="text-popins text-white ">Hingga Tanggal </span>
        </div>
        <div class="row q-my-xs">
          <div class="col-10" style="height: 26px !important;">
            <q-input bg-color="white"  v-model="filter.tgl_sampai" @change="getView" outlined type="date" :dense="true"></q-input>
          </div>
          <div class="col-2">
            <q-btn @click="filter.tgl_dari = '', filter.tgl_sampai= '' , getView()" color="black" style=" font-size:10px; height:36px;width: 60px;"
              icon="autorenew" text-color="orange-5" />
          </div>
        </div>
      </div>
      <div class="col col-xs-12 col-sm-12 col-md-4 q-px-md self-center items-center">
        <div desktop class="q-py-sm h_filterBar">
          <span class="text-popins text-white ">Cari Unit / Pasar </span>
        </div>
        <div class="row q-my-xs">
          <div class="col-10">
              <model-list-select :list="list_unit" v-model="filter.unit" :select="getView()" option-value="id" option-text="uraian"
              name="uraian" placeholder="Cari Unit / Pasar" @click="fUnit()" @searchchange="fUnit">
              </model-list-select> 
          </div>
          <div class="col-2">
              <q-btn @click="filter.unit = '', getView()" color="black" style="font-size:10px; height:36px;width: 60px;"
              icon="autorenew" />
          </div>
        </div> 

      </div>

    </div>  

    <div class="row " >
 
      <div class="col col-xs-12 col-sm-12 col-md-3  q-pa-md">
        <q-card class="my-card" >
          <q-card-section class="" style="background-color: #434343;" horizontal>
            <q-btn flat color="white" icon="storefront" /> 
            <q-card-actions vertical class="justify-around q-px-md bg-white" style="width: 100%;">
              <div>
                <span class="text-weight-bold" style="color: #434343;font-size: 9pt;">Total Jumlah Pasar</span>
              </div>
              <div class="">
                <span class="" style="color: #434343;"> {{total_jml_pasar}} </span>
              </div> 
            </q-card-actions>
          </q-card-section>
        </q-card>        
      </div>

      <div class="col col-xs-12 col-sm-12 col-md-3  q-pa-md">
        <q-card class="my-card" >
          <q-card-section class="" style="background-color: #434343;" horizontal>
            <q-btn flat color="white" icon="store" /> 
            <q-card-actions vertical class="justify-around q-px-md bg-white" style="width: 100%;">
              <div>
                <span class="text-weight-bold" style="color: #434343;font-size: 9pt;">Total Jumlah Usaha</span>
              </div>
              <div>
                <span class="" style="color: #434343;"> {{total_jml_usaha}} </span>
              </div> 
            </q-card-actions>
          </q-card-section>
        </q-card>        
      </div>

      <div class="col col-xs-12 col-sm-12 col-md-3  q-pa-md">
        <q-card class="my-card" >
          <q-card-section class="" style="background-color: #434343;" horizontal>
            <q-btn flat color="white" icon="workspace_premium" /> 
            <q-card-actions vertical class="justify-around q-px-md bg-white" style="width: 100%;">
              <div>
                <span class="text-weight-bold" style="color: #434343;font-size: 9pt;">Total Izin Usaha</span>
              </div>
              <div>
                <span class="" style="color: #434343;"> {{total_jml_izin_usaha}} </span>
              </div> 
            </q-card-actions>
          </q-card-section>
        </q-card>        
      </div>

      <div class="col col-xs-12 col-sm-12 col-md-3  q-pa-md">
        <q-card class="my-card" >
          <q-card-section class="" style="background-color: #434343;" horizontal>
            <q-btn flat color="white" icon="paid" /> 
            <q-card-actions vertical class="justify-around q-px-md bg-white" style="width: 100%;">
              <div>
                <span class="text-weight-bold" style="color: #434343; font-size: 9pt;">Total Retribusi</span>
              </div>
              <div>
                <span class="" style="color: #434343;">  {{UMUM.matauang(total_jml_retribusi)}} </span>
              </div> 
            </q-card-actions>
          </q-card-section>
        </q-card>        
      </div>

      <!-- ================================================= BATAS ================================================= -->

      <div class="col col-xs-12 col-sm-12 col-md-6  q-pa-md">

        <q-card class="my-card text-black bg_grayRad" style="height:530px; "> 
          <q-card-section class="q-pt-none"> 
              <div id="container"> 
              </div> 
          </q-card-section>
        </q-card>
      </div>
      
      <div class="col col-xs-12 col-sm-12 col-md-6  q-pa-md">

        <q-card class="my-card text-black bg_grayRad" style="height:530px"> 
          <q-card-section class="q-pt-none">
             <div id="container2" > 
             </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col col-xs-12 col-sm-12 col-md-12 self-center q-pa-md">

        <q-card class="my-card text-black" style="background: radial-gradient(circle, #ffff 0%, #ffff 100%)">  
          <q-card-section class="q-pt-none"> 
            <div id="container3"></div> 
          </q-card-section>
        </q-card>
      </div>

      <div class="col col-xs-12 col-sm-12 col-md-12 self-center q-pa-md"> 
        <q-card class="my-card text-black" style="background: radial-gradient(circle, #ffff 0%, #ffff 100%)">
          <q-card-section> 
          </q-card-section>

          <q-card-section class="q-pt-none">

            <div id="container4"></div>
             
          </q-card-section>
        </q-card>
      </div>

      <div class="col col-xs-12 col-sm-12 col-md-12 self-center q-pa-md"> 
        <q-card class="my-card text-black" style="background: radial-gradient(circle, #ffff 0%, #ffff 100%)">
          <q-card-section class="q-pt-none"> 
            <div id="container6"></div>
          </q-card-section>
        </q-card>
      </div>

      <div class="col col-xs-12 col-sm-12 col-md-12 self-center q-pa-md"> 
        <q-card class="my-card text-black" style="background: radial-gradient(circle, #ffff 0%, #ffff 100%)">
          <q-card-section class="q-pa-sm"> 
            <div class="text-center q-py-md">
              <div style="font-size: 12pt; font-weight: 600;">DATA LAPORAN RETRIBUSI MASUK</div>
              <div v-if="list_laporan_pasar.hingga !='' " style="font-size: 10pt; font-weight: 600;">Dari Tanggal {{UMUM.tglConvert(list_laporan_pasar.dari).tgl}} Hingga {{UMUM.tglConvert(list_laporan_pasar.hingga).tgl}} </div>
            </div>
            <table style="width: 100%;">
              <tr>
                <td style="width: 10%;text-align: center;"><b>NO</b></td>
                <td style="width: 50%;text-align: center;"><b>NAMA PASAR</b></td>
                <td style="width: 40%;text-align: center;"><b>TOTAL LAPORAN RETRIBUSI</b></td>
              </tr>
              <tr v-for="(data, index) in list_laporan_pasar.data" :key="data.id">
                <td class="text-center">{{index+1}}</td>
                <td class="text-left">{{data.nama_pasar}}</td> 
                <td class="text-right">{{UMUM.matauang(data.jumlah_setor)}}</td>
              </tr>
            </table>
          </q-card-section>
        </q-card>
      </div>


    </div>
 

      
  </div>
</template>

<script>
// @ is an alias to /src
import CHART from "../library/chart";
import UMUM from "../library/umum";

import HelloWorld from '@/components/HelloWorld.vue'
import FETCHING from "../library/fetching";

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      slide: 'first',
      FETCHING  : FETCHING,
      UMUM      : UMUM,
      CHART     : CHART,
      filter: {
        kec   : '',
        unit  : '',
        tgl_dari      : '',
        tgl_sampai    : '',
      },
      list_unit   : [],
      list_kec    : [],
      list_deskel : [],
      list_data_laporan_pasar : [
        {id:'1', uraian : 'pas', total : 250000},
        {id:'2', uraian : 'pasar 2', total : 270000},
        {id:'3', uraian : 'pasar 3', total : 280000},
      ],

      datax       : [],

      total_jml_pasar       :'',
      total_jml_usaha       :'',
      total_jml_izin_usaha  :'',
      total_jml_retribusi   :'',

      list_jml_transaksi_unit   :[],
      list_laporan_pasar   :[],
      // datax:    [{
      //               name: 'Chrome',
      //               y: 63.06,
      //               drilldown: 'Chrome'
      //           }, ]
    }
  },
  methods: {
    getView() {
      // console.log('this get view');

      this.usaha()
      this.izin_usaha()
      this.unitTransaksi()
      this.jml_transaksi_unit()      
      this.jml_laporan_pasar()      
    },

    // ========================================= CHART
      async usaha() { 
        var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "usaha", { 
          unit        : this.filter.unit,
          tgl_dari    : this.filter.tgl_dari,
          tgl_sampai  : this.filter.tgl_sampai,
          cari: '',
        })  
        this.data1(data)   
      }, 

      async izin_usaha() { 
        var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "izin_usaha", { 
          unit        : this.filter.unit,
          tgl_dari    : this.filter.tgl_dari,
          tgl_sampai  : this.filter.tgl_sampai,
          cari: '',
        })  
        // console.log(data);
        var hasil = []
        for (var i in data){
          hasil.push({name:data[i].name, y:(data[i].pemilik_izin*100)/data[i].TOTAL, 'total':data[i].TOTAL})
        } 
        // console.log(hasil);
        this.chart2(hasil)

      }, 

      async unitTransaksi() { 
        var a = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "unitTransaksi", { 
          unit        : this.filter.unit,
          tgl_dari    : this.filter.tgl_dari,
          tgl_sampai  : this.filter.tgl_sampai,          
          cari: '',
        })
        this.chart3(a)   

      }, 

      async jml_transaksi_unit() { 
        var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "jml_transaksi_unit", { 
          unit        : this.filter.unit,
          tgl_dari    : this.filter.tgl_dari,
          tgl_sampai  : this.filter.tgl_sampai,          
          cari: '',
        })    
        var a = this.data4(data)
      }, 
      async jml_laporan_pasar() { 
        var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "jml_laporan_pasar", { 
          unit        : this.filter.unit,
          tgl_dari    : this.filter.tgl_dari,
          tgl_sampai  : this.filter.tgl_sampai,          
          cari: '',
        })    
        console.log(data);
        this.list_laporan_pasar = data
      }, 

    // ========================================= CHART


    // ================================================ FILTER ================================================ 
    // ================================================ CARD  ================================================ 
      async jml_pasar() { 
        var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "jml_pasar", { 
          cari: '',
        })  
        this.total_jml_pasar = data[0].total
      }, 

      async jml_usaha() { 
        var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "jml_usaha", { 
          cari: '',
        })  
        this.total_jml_usaha = data[0].total
      }, 
      async jml_izin_usaha() { 
        var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "jml_izin_usaha", { 
          cari: '',
        })  
        this.total_jml_izin_usaha = data[0].total
      }, 
      async jml_retribusi() { 
        var data = await FETCHING.FETCH_POST(this.$store.state.url.URL_DASHBOARD + "jml_retribusi", { 
          cari: '',
        })  
        this.total_jml_retribusi = data[0].total
      },  
    // ================================================ CARD  ================================================ 
    // ================================================ FILTER ================================================     

    data1(datax){
      this.CHART.barchartDrill2('container', 'Jumlah Usaha', datax)
    },  
    chart2(datax){ 
         this.CHART.barchartDrill('container2', 'Data Izin Usaha', datax)
      },
    chart3(datax){ 
      this.CHART.barChart('container3', 'Total Transaksi Unit', datax)
    }, 
    data4(datax){ 
      this.CHART.lineChart('container4', 'GRAFIK PEMBAYARAN PASAR', datax)
    },  

    async fUnit(searchText){
        this.list_unit = await FETCHING.FETCH_POST(this.$store.state.url.LIST_FILTER + "unit", {
            kec_id    : '',
            deskel_id : '',
            cari: searchText,
        })
        this.getView()                 
    },

  },
  mounted() {
    this.unitTransaksi()
    this.izin_usaha()
    this.usaha()
    this.jml_transaksi_unit()
    this.jml_laporan_pasar()


    this.data1()
    this.chart2()
    // this.data6()
    // this.data4()

    this.jml_pasar()
    this.jml_usaha()
    this.jml_izin_usaha()
    this.jml_retribusi()

    this.$store.state.leftDrawerOpen = true
  },
}
</script>


<style>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 16px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.bg-01 {
  background-color: #112C33;
  background-image: url("../assets/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 921px;
}

.bga {
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contener {
  height: 1024px;
  width: 1440px;
  left: -720px;
  top: -513px;
  border-radius: 0px;
}

/* KALAU DESKTOP  */

/* KALAU MOBILE  */
</style>

<style scoped>
.highcharts-figure,
.highcharts-data-table table {
    min-width: 360px;
    max-width: auto;
    margin: 1em auto;
}


.h_filterBar{
  margin-bottom: -5px; 
  font-size: 12px;
}



@media (min-width: 1281px) {
  
  .marginif{
    padding-top: 180px; 
  }
  
}
 

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .marginif{
    padding-top: 180px; 
  }
  
} 

@media (min-width: 768px) and (max-width: 1024px) {
  
  .marginif{
    padding-top: 329px; 
  }
  .h_filterBar{
  /* margin-bottom: -10px;  */
  font-size: 9px;
  }
} 

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .marginif{
    padding-top: 329px; 
  }
  
} 

@media (min-width: 481px) and (max-width: 767px) {
  
  .marginif{
    padding-top: 329px; 
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  .marginif{
    margin-top: 329px; 
  }
  
}

</style>

